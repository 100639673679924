import moment from 'moment';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import {
    BrokerDealerBwicPositionSearchResult,
    BwicPositionSearchResult,
    SellerBwicPositionSearchResult
} from '../../types/bwic-monitor/BwicPositionSearchResult';
import { BwicSearchResult } from '../../types/bwic-monitor/BwicSearchResult';
import { collapseStickRightColumn } from '../bidding/common/table/CollapseStickRightColumn';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { allBWICSActions } from '../../actions';
import { BwicStatusLabel, EmptyPlaceholder, Preloader, TickerButton } from '../common';
import { biddingUtils, dateTimeUtils, formatUtils, isRequestNone, isRequesting, moneyUtils } from '../../utils';
import { PxTalkListButton } from '../common/PxTalk/PxTalkListButton';
import { user } from '../../user/user';
import { roles, constants } from '../../constants';
import { BidLevel } from '../../types/bidding/BidLevel';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { BidLevel as BidLevelComponent } from '../bidding/seller/biddingSecurities/BidLevel';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { isActiveTrade, TradeStatus } from '../../types/trades/TradeStatus';
import { Table } from '../bidding/common/table/Table';
import { MyBwicCustomButtons } from './MyBwicCustomButtons';
import { AllBWICCustomButtons } from './AllBWICCustomButtons';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { TableColumnStickType } from '../bidding/common/table/types/TableColumnStickType';
import { BuyerBidButton } from './BuyerBidButton';
import { ViewBwicRulesButton } from './ViewBwicRulesButton';
import { BwicTradedAmount } from './BwicTradedAmount';
import { AllBwicsControlPanel } from './AllBwicsControlPanel';
import { CompanyDetailsPanel } from '../company-details/CompanyDetailsPanel';
import { InfoTooltip } from '../common/InfoTooltip';
import { SameDayBwicIcon } from '../common/SameDayBwicIcon';
import { SecurityInventoryLink } from '../inventory/SecurityInventoryLink';
import { StickyTableRow } from '../bidding/common/table/sticky/StickyTableRow';
import { BwicProcessType } from '../../types/models/Process';
import { BiddingInfo } from '../bidding/brokerDealer/biddingSecurities/BiddingInfo';
import { BwicStatusTimerLabel } from '../common/BwicStatusTimerLabel';
import { LiveBiddingStage2Timer } from '../bidding/common/LiveBiddingStage2Timer';
import { BidFeedback, FeedbackCurrentBid } from '../bidding/brokerDealer/biddingSecurities/BidFeedback';
import { TradeAction } from '../trade/TradeAction';
import { IntexLink } from '../common/IntexLink';
import { AllBwicsTab } from './AllBwicsTab';
import { FinalIcon } from '../bidding/common/FinalIcon';
import { AxedIcon } from '../bidding/common/AxedIcon';
import { BloombergLink } from '../common/BloombergLink';
import { getAmrColumns } from '../amrPipeline/common-columns';
import { values } from 'lodash';
import { ValitanaLink } from '../common/ValitanaLink';
import { PipelineColumn } from '../amrPipeline/types/PipelineColumn';
import { liveBiddingUtils } from '../../utils/live-bidding.utils';
import { EvalKTalkLabel } from '../common/PxTalk/EvalKTalkLabel';
import { createColumnListBuilder } from '../../utils/ColumnListBuilder';
import { ColumnConfigKey } from '../../types/page-config/column-customizer/ColumnConfigKey';
import { PageConfig } from '../../types/page-config/PageConfig';
import { ListBuilder } from '../../utils/ListBuilder';
import { OpenBiddingStatus } from '../../types/enums/OpenBiddingStatus';
import { LiveBiddingStage2Level } from '../bidding/common/feedback/LiveBiddingStage2Level';
import { MyBwicIcon } from '../common/MyBwicIcon';
import { ImDealLookupLink } from '../amrPipeline/common/ImDealLookupLink';
import { BwicCreator } from '../bidding/brokerDealer';
import { LiveBiddingStrage2LevelData } from '../bidding/common/feedback/LiveBiddingStrage2LevelData';
import { IntersectionObserverProvider } from '../common/IntersectionObsereverProvider';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { ViewDealDocumentsButton } from '../documents/deal-documents-popup/ViewDealDocumentButton';

type TPositionContext = {
    bwic: BwicSearchResult;
};

interface Props {
    activeInventorySecurityIdByCount: { [securityId: number]: number };
    onCancel: (bwic: BwicSearchResult) => void;
    activeTab: AllBwicsTab,
    pageConfig?: PageConfig
}

export function BwicGroupView({ activeInventorySecurityIdByCount, onCancel, activeTab, pageConfig }: Props) {
    const dispatch = useAppDispatch();
    const columnsConfig = user.hasFeatures(SubscriptionFeature.BwicMonitorColumnCustomizer)
        ? pageConfig?.columns
        : undefined;
    const isSeller = user.hasRoles(...roles.seller());
    const isSellerTrader = isSeller && user.hasRoles(roles.SellerTrader);
    const isBrokerDealer = user.hasRoles(...roles.bd());
    const isBrokerDealerTrader = isBrokerDealer && user.hasRoles(roles.BrokerDealerTrader);
    const isSellerAdmin = user.hasRoles(roles.SellerAdministrator);
    const isDataEntry = user.hasRoles(roles.DataEntry);
    const isViewer = user.hasRoles(roles.Viewer);
    const isMedia = user.hasRoles(roles.Media);

    const bwics = useSelector((s: AppState) => s.allBWICS.bwics);

    const totalRecordNumber = useSelector((s: AppState) => s.allBWICS.totalRecordNumber);
    const activeBwic = useSelector((s: AppState) =>
        s.allBWICS.bwics &&
        s.allBWICS.bwics.find(b => b.referenceName === s.allBWICS.activeBwicReferenceName));
    const activePositionId = useSelector((s: AppState) =>
        (s.bwicHistory.visible && s.bwicHistory.positionId) ||
        (s.securityDetails.security && s.securityDetails.security.id) ||
        (s.bwicLog.security && s.bwicLog.security.id) ||
        (s.rules.show && s.rules.positionId) ||
        (s.pxTalkList.visible && s.pxTalkList.positionId)
    );
    const companyDetailsPanelVisible: boolean = useSelector((state: AppState) => state.companyDetails.isPanelVisible);
    const isAllTab = activeTab === AllBwicsTab.All;
    const isBuyTab = activeTab === AllBwicsTab.Buy;
    const isSellTab = activeTab === AllBwicsTab.Sell
    const isBdBuyTab = isBrokerDealer && isBuyTab;
    const isBdSellTab = isBrokerDealer && isSellTab;
    const { [PipelineColumn.manager]: managerAmrColumn, ...restAmrColumns } = getAmrColumns();
    const manager = { ...managerAmrColumn, sortingField: undefined };

    const getBwicColumns = (): IColumnDefinition<BwicSearchResult>[] => {
        const bwicStatus = {
            columnKey: 'bwicStatus',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult) =>
                <BwicStatusLabel
                    status={bwic.status}
                    directBidding={bwic.isClearingBankParticipant}
                    isParsed={bwic.isParsed}
                    liveBidding={bwic.process.type === BwicProcessType.Live}
                    isAllToAll={bwic.isAllToAll}
                />,
            bodyClassName: 'data-list-cell-lg cell-bwic-status'
        };
        const myFlag = {
            columnKey: 'my-flag',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult) => (
                <MyBwicIcon bwic={bwic} />
            ),
            bodyClassName: 'data-list-cell-xxxs padding-l-0'
        };
        const sameDay = {
            columnKey: 'same-day',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult) => {
                return (
                    <>
                        {bwic.isSameDayBwic && <SameDayBwicIcon />}
                    </>
                )
            },
            bodyClassName: 'data-list-cell-xxxs padding-l-0'
        };
        const dueDate = {
            columnKey: 'dueDate',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult) => {
                if (!bwic.bidsDueDateUtc) return '';

                const bidsDue = moment.utc(bwic.bidsDueDateUtc).local();

                const date = moment().isSame(bidsDue, 'd')
                    ? `Today, ${dateTimeUtils.utcToLocalString(bwic.bidsDueDateUtc)}`
                    : dateTimeUtils.utcToLocalString(bwic.bidsDueDateUtc, 'ddd, MMM D, YYYY hh:mm A');

                return date;
            },
            bodyClassName: 'data-list-cell-xl-xxl padding-l-0 text-bold'
        };
        const bwicName = {
            columnKey: 'bwic-name',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult) => bwic.name,
            bodyClassName: 'data-list-cell-xl'
        };
        const bwicRules = {
            columnKey: 'bwic-rules',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult, context: { rowIndex: number }) =>
                <ViewBwicRulesButton bwic={bwic} rowIndex={context.rowIndex} isBrokerDealer={isBrokerDealer} />,
            bodyClassName: 'data-list-cell-xl padding-l-0 overflow-visible'
        };
        const companyDetailsButton = {
            columnKey: 'company-details-button',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult, context: { rowIndex: number }) => {
                return (
                    <>
                        {
                            Boolean(bwic.isParticipant) &&
                            bwic.seller != null &&
                            <div className="created-by-cnt">
                                <BwicCreator rowKey={context.rowIndex} seller={bwic.seller} />
                            </div>
                        }
                    </>
                );
            },
            bodyClassName: 'data-list-cell-xl'
        };
        const tradedAmount = {
            columnKey: 'tradedAmount',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult) => isSellTab && <BwicTradedAmount bwic={bwic} />,
            bodyClassName: 'data-list-cell-xl'
        };
        const goodUntil = {
            columnKey: 'bwic-good-until',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult) => !bwic.isParsed && bwic.status === BwicStatus.bidding
                ? <span className="flex-none text-red">
                    <i className="icon icon-time"></i>
                    {bwic.goodUntilDateUtc ? dateTimeUtils.utcToLocalString(bwic.goodUntilDateUtc) : ''}
                </span>
                : null,
            bodyClassName: 'data-list-cell-xl text-right'
        };
        const bwicActions = {
            columnKey: 'bwicActions',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (bwic: BwicSearchResult, context: { rowIndex: number }) => isBuyTab || isSellTab
                ? <MyBwicCustomButtons bwic={bwic} bwicIndex={context.rowIndex} onCancel={onCancel} activeTab={activeTab} />
                : <AllBWICCustomButtons bwic={bwic} bwicIndex={context.rowIndex} />
            ,
            bodyClassName: 'data-list-cell-md text-right overflow-visible keep-while-collapsed',
            stickRight: true
        };

        const getEmptyCollapseRowCell = () => {
            let itemNumber = 0;
            return (className?: string, columnKey: string = '') => {
                itemNumber++;
                return ({
                    columnKey: `${columnKey}-emptyCollapseRowCell-${itemNumber}`,
                    renderColumnHeaderContent: () => '',
                    renderColumnContent: () => '',
                    className,
                    stickRight: true
                })
            }
        }

        const getEmptyPlaceholderColumns = () => {
            const [, , right] = getPositionListColumns();
            const emptyCollapseRowCell = getEmptyCollapseRowCell();

            return right
                .filter(x => !(x.column.bodyClassName || x.column.className)?.includes('keep-while-collapsed'))
                .map(x => emptyCollapseRowCell(x.column.className, x.column.columnKey));
        }

        const columns = new ListBuilder<IColumnDefinition<BwicSearchResult>>()
            .add(bwicStatus)
            .add(myFlag, sameDay, dueDate, bwicName, bwicRules)
            .addWhen(() => isSeller || isBrokerDealerTrader, tradedAmount)
            .addWhen(() => isBrokerDealer, companyDetailsButton)
            .add(goodUntil)
            .addWhen(() => !isViewer, collapseStickRightColumn)
            .add(bwicActions)
            .result();

        return [...getEmptyPlaceholderColumns(), ...columns];
    }

    const renderLevel = (level: BidLevel, bwic: BwicSearchResult, position: BwicPositionSearchResult) => {
        if (bwic.status === BwicStatus.canceled) return constants.emptyPlaceholder;

        const sellerPosition = (position as SellerBwicPositionSearchResult);

        return <BidLevelComponent
            bwicReferenceName={bwic.referenceName}
            positionId={position.id}
            bwicStatus={bwic.status}
            level={level}
            bids={sellerPosition.bids}
            trade={sellerPosition.trade}
            allowFeedback={false}
            process={bwic.process}
        />;
    }

    const getPositionListColumns = (): ColumnBuilder<BwicPositionSearchResult>[][] => {
        const canViewDealLookupLink = isAllTab && user.hasRoles(...roles.issuanceMonitorAccess());
        const canViewBuyerBidButton = isAllTab && (isSeller || isDataEntry);

        const isinCusip: IColumnDefinition<BwicPositionSearchResult> = {
            columnKey: 'isinCusip',
            configKey: ColumnConfigKey.IsinCusip,
            renderColumnHeaderContent: () => 'ISIN/CUSIP',
            renderColumnContent: position => position.isinCusip,
            className: 'data-list-cell-lg-02'
        };
        const ticker: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'ticker',
            configKey: ColumnConfigKey.Ticker,
            renderColumnHeaderContent: () => 'Ticker',
            renderColumnContent: (position, context) =>
                <TickerButton
                    security={position}
                    fetchAgencyRatings={true}
                    onClick={() => dispatch(allBWICSActions.setActiveBwic(context.bwic.referenceName))}
                />,
            className: 'data-list-cell-lg'
        };
        const viewInventory: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'view-invnetory',
            configKey: ColumnConfigKey.Inventory,
            renderColumnHeaderContent: () => (
                <>
                    Inventory
                    <InfoTooltip text={`Click on ‘View’ to see this security in the ${isBrokerDealer ? '' : 'Dealer'} Inventory`} />
                </>
            ),
            renderColumnContent: (position, { bwic }) =>
                ((bwic.status === BwicStatus.scheduled || bwic.status === BwicStatus.bidding) && activeInventorySecurityIdByCount[position.securityId])
                    ? (
                        <SecurityInventoryLink
                            count={activeInventorySecurityIdByCount[position.securityId]}
                            ticker={position.ticker}
                        />
                    ) : constants.emptyPlaceholder,
            className: 'data-list-cell-sm'
        };
        const asset = {
            columnKey: 'asset',
            configKey: ColumnConfigKey.Asset,
            renderColumnHeaderContent: () => 'Asset',
            renderColumnContent: (position: BwicPositionSearchResult) => position.asset,
            className: 'data-list-cell-xs'
        };
        const ccy = {
            columnKey: 'currency',
            configKey: ColumnConfigKey.Currency,
            renderColumnHeaderContent: () => 'Ccy',
            renderColumnContent: (position: BwicPositionSearchResult) => position.currency,
            className: 'data-list-cell-xs'
        };
        const rating = {
            columnKey: 'rating',
            configKey: ColumnConfigKey.Rating,
            renderColumnHeaderContent: () => 'Rtg',
            renderColumnContent: (position: BwicPositionSearchResult) => position.rating,
            className: 'data-list-cell-xs'
        };
        const size = {
            columnKey: 'size',
            configKey: ColumnConfigKey.Size,
            renderColumnHeaderContent: () => 'Size, MM',
            renderColumnContent: (position: BwicPositionSearchResult) => moneyUtils.amountToMM(position.size),
            className: 'text-right'
        };
        const evalPriceTalk: IColumnDefinition<BwicPositionSearchResult> = {
            columnKey: 'evalPriceTalk',
            configKey: ColumnConfigKey.EvalPriceTalk,
            renderColumnHeaderContent: () => <EvalKTalkLabel withInfoButton />,
            renderColumnContent: position => position.evalPriceTalk ?? constants.emptyPlaceholder,
            className: 'data-list-cell-sm text-right data-list-cell-eval',
        };
        const priceTalk: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'price-talk',
            configKey: ColumnConfigKey.PxTalk,
            renderColumnHeaderContent: () => 'Px Talk',
            renderColumnContent: (position, { bwic }) =>
                <PxTalkListButton
                    className="pseudo-link"
                    positionId={position.id}
                    pxTalks={position.pxTalks}
                    requiredFeature={bwic.isParsed && SubscriptionFeature.BwicMonitorBwicsData}
                    onClick={() => dispatch(allBWICSActions.setActiveBwic(bwic.referenceName))}
                />,
            className: 'data-list-cell-sm text-right'
        };
        const color = {
            columnKey: 'color',
            configKey: ColumnConfigKey.Color,
            renderColumnHeaderContent: () => 'Color',
            renderColumnContent: (position: BwicPositionSearchResult) =>
                position.color ? formatUtils.formatColor(position.color) : constants.emptyPlaceholder,
            className: 'data-list-cell-md'
        };
        const buyerBidButton: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'buyer-buy-button',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (position, { bwic }) => {
                if (!bwic.isMy && bwic.isClearingBankParticipant) {
                    return <BuyerBidButton bwic={bwic} position={position} />
                }
            },
            className: 'data-list-cell data-list-cell-md text-right keep-while-collapsed',
            stickRight: true
        };
        const brokerDealerFeedback: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'bd-feedback',
            renderColumnHeaderContent: () => 'Feedback',
            renderColumnContent: (p, { bwic }) => {
                const position = p as BrokerDealerBwicPositionSearchResult
                const currentBid = biddingUtils.getCurrentBid(position.bids);

                return bwic.process.type === BwicProcessType.Live
                    ? <LiveBiddingStrage2LevelData positionId={p.id}>
                        {
                            (requestState, data) =>
                                <Preloader small inProgress={isRequesting(requestState) || isRequestNone(requestState)}>
                                    {
                                        data == null
                                            ? <>{constants.emptyPlaceholder}</>
                                            : (<div className="live-bidding-feedback">
                                                <LiveBiddingStage2Level
                                                    stagedBiddingStatus={bwic.process.stagedBiddingStatus}
                                                    levelSpecificationType={bwic.process.liveBidding!.levelSpecificationType}
                                                    currentBid={currentBid}
                                                    stage2Level={data.openBiddingStage2Level}
                                                    tiedToBest={data.tiedForBest}
                                                    animated={bwic.status === BwicStatus.bidding}
                                                />
                                            </div>
                                            )
                                    }
                                </Preloader>
                        }
                    </LiveBiddingStrage2LevelData>
                    : <BidFeedback
                        process={bwic.process}
                        bwicStatus={bwic.status}
                        feedback={currentBid?.feedback}
                        feedbackDate={currentBid?.feedbackCreatedUtc}
                        bidRequest={position.bidRequest}
                        currentBid={currentBid as FeedbackCurrentBid}
                        positionId={position.id}
                        bwicReferenceName={bwic.referenceName}
                        bidsDueUtc={bwic.bidsDueDateUtc}
                    />;
            },
            className: 'data-list-cell-xxl-flexible cell-flexible-text cell-feedback'
        };
        const brokerDealerBiddingInfo: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'bd-bidding-info',
            renderColumnHeaderContent: () => 'Bidding Info',
            renderColumnContent: (p, { bwic }) => {
                const position = p as BrokerDealerBwicPositionSearchResult
                const currentBid = biddingUtils.getCurrentBid(position.bids);
                const liveBiddingStage2Expired = bwic.process.type === BwicProcessType.Live
                    ? liveBiddingUtils.checkLiveBiddingStage2Expired(bwic.process, bwic.bidsDueDateUtc)
                    : undefined;

                return <BiddingInfo
                    process={bwic.process}
                    bwicStatus={bwic.status}
                    currentBid={currentBid as FeedbackCurrentBid}
                    isColorDistribution={bwic.isColorDistribution}
                    canBidOnStage2={position.canBidOnStage2 ?? position.isStage2Participant}
                    expired={liveBiddingStage2Expired}
                />;
            },
            className: 'data-list-cell-lg-02'
        };
        const brokerDealerBid = {
            columnKey: 'bid',
            renderColumnHeaderContent: () => 'My Bid',
            renderColumnContent: (position: BwicPositionSearchResult) => {
                const currentBid = biddingUtils.getCurrentBid((position as BrokerDealerBwicPositionSearchResult).bids);
                const isPass = Boolean(currentBid?.pass);
                const hasBidValue = Number(currentBid?.value) > 0;

                return (
                    <div className="flex-row justify-content-end">
                        {isPass && 'Pass'}
                        {hasBidValue && formatUtils.formatBid(currentBid.value)}
                        {!isPass && !hasBidValue && constants.emptyPlaceholder}
                        <div className="axed-final-icons">
                            {hasBidValue && currentBid.axed && <AxedIcon />}
                            {hasBidValue && currentBid.final && <FinalIcon />}
                        </div>
                    </div>
                );
            },
            className: 'data-list-cell-lg cell-bid-padding text-right'
        };
        const liveBiddingStage2Timer: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'liveBiddingStage2Timer',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (p, { bwic }) => {
                const position = p as BrokerDealerBwicPositionSearchResult;
                const isBidding = bwic.status === BwicStatus.bidding;

                if (position.isTradedAway || isActiveTrade(position.trade) || !isBidding) return constants.emptyPlaceholder;

                const isLiveBiddingStage2 =
                    isBidding &&
                    bwic.process.type === BwicProcessType.Live &&
                    bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;

                if (isLiveBiddingStage2) {
                    return (
                        <LiveBiddingStage2Timer
                            bidsDueDateUtc={bwic.bidsDueDateUtc}
                            canBidOnStage2={Boolean(position.canBidOnStage2)}
                            process={bwic.process}
                            latestBidSubmission={position.latestBidSubmission}
                            onExpired={() => dispatch(allBWICSActions.liveBiddingStage2Expired(position.id))}
                        />
                    );
                }

                return <BwicStatusTimerLabel
                    status={bwic.status}
                    liveBidding={bwic.process.type === BwicProcessType.Live}
                    isParsed={bwic.isParsed}
                    directBidding={bwic.isClearingBankParticipant}
                    bidsDueUtc={bwic.bidsDueDateUtc}
                    goodUntilUtc={bwic.goodUntilDateUtc}
                    process={bwic.process}
                    isAllToAll={bwic.isAllToAll}
                    bwicTypeIconVisible={false}
                    renderEmpty={() => <>{constants.emptyPlaceholder}</>}
                />

            },
            className: 'data-list-cell-sm'
        };
        const docs: IColumnDefinition<BwicPositionSearchResult> = {
            columnKey: 'deal-documents',
            configKey: ColumnConfigKey.DealDocuments,
            renderColumnHeaderContent: () => 'Docs',
            renderColumnContent: (position, context) => position.documentsCount
                ? <ViewDealDocumentsButton
                    className="text-regular"
                    security={position}
                    requiredFeature={context.bwic.isParsed ? SubscriptionFeature.CanAccessDealDocuments : null}
                />
                : constants.emptyPlaceholder,
            className: '',
        };
        const brokerDealerAction: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'bdActions',
            configKey: ColumnConfigKey.Actions,
            renderColumnHeaderContent: () => '',
            renderColumnContent: (position, { bwic }) => !bwic.isMy && (
                <TradeAction
                    trade={(position as BrokerDealerBwicPositionSearchResult).trade}
                    isTradedAway={(position as BrokerDealerBwicPositionSearchResult).isTradedAway}
                    positionId={position.id}
                    ticker={position.ticker}
                />
            ),
            className: 'data-list-cell-md cell-bd-trade-action padding-r-0 text-right keep-while-collapsed',
            stickRight: true,
        };
        const best: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'best',
            renderColumnHeaderContent: () => 'Best',
            renderColumnContent: (p, { bwic }) => renderLevel(BidLevel.Best, bwic, p),
            className: 'data-list-cell-lg-02'
        };
        const cover: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'cover',
            renderColumnHeaderContent: () => 'Cover',
            renderColumnContent: (p, { bwic }) => renderLevel(BidLevel.Cover, bwic, p),
            className: 'data-list-cell-lg-02'
        };
        const third: IColumnDefinition<BwicPositionSearchResult, TPositionContext> = {
            columnKey: 'third',
            renderColumnHeaderContent: () => 'Third',
            renderColumnContent: (p, { bwic }) => renderLevel(BidLevel.Third, bwic, p),
            className: 'data-list-cell-lg-02'
        };
        const externalLinks = {
            columnKey: 'externalLinks',
            renderColumnHeaderContent: () => 'Actions',
            renderColumnContent: (position: BwicPositionSearchResult) =>
                <>
                    <IntexLink positionId={position.isinCusip} />
                    <BloombergLink identifier={position.isinCusip} />
                    <ValitanaLink identifier={position.isinCusip} />
                </>,
            className: 'cell-external-links padding-r-0 text-right',
            stickRight: true
        };
        const dealLookupLink = {
            columnKey: 'dealLookupLink',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (position: BwicPositionSearchResult) =>
                <>
                    {
                        !!position.dealReferenceName &&
                        !!position.dealLegalName &&
                        <ImDealLookupLink
                            dealReferenceName={position.dealReferenceName}
                            dealLegalName={position.dealLegalName}
                        />
                    }
                </>,
            className: 'data-list-cell-xxxs',
            stickRight: true
        };
        const bwicActionsPlaceholder = {
            columnKey: 'bwicActionsPlaceholder',
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: "data-list-cell-md text-right  keep-while-collapsed",
            stickRight: true
        };

        const canBrokerDealerBid =
            isBuyTab &&
            isBrokerDealerTrader;

        const amrColumns = values(restAmrColumns).map(c => ({ ...c, sortingField: undefined }));

        const middleColumns = createColumnListBuilder<BwicPositionSearchResult>(columnsConfig)
            .addWhen(() => canBrokerDealerBid, liveBiddingStage2Timer)
            .addWhen(() => isSeller || isSellerAdmin || isAllTab || isBdSellTab, isinCusip)
            .addWhen(() => canBrokerDealerBid, brokerDealerBiddingInfo)
            .add(ticker)
            .addWhen(() => isAllTab, docs, manager)
            .add(viewInventory, asset, ccy, rating, size)
            .addWhen(() => !isMedia, evalPriceTalk)
            .add(priceTalk)
            .addWhen(() => isAllTab, color, ...amrColumns)
            .addWhen(() => isAllTab && (isSeller || isSellerAdmin || isBrokerDealer))
            .addWhen(() => isSellTab && (isSeller || isSellerAdmin || isBrokerDealer), best, cover, third)
            .addWhen(() => canBrokerDealerBid, brokerDealerBid, brokerDealerFeedback)
            .addWhen(() => isBdBuyTab, color)
            .result()
            .map(c => new ColumnBuilder(c));

        const rightColumns = createColumnListBuilder<BwicPositionSearchResult>(columnsConfig)
            .addWhen(() => !isViewer, collapseStickRightColumn)
            // brokerDealerAction or buyerBidButton will serve as placeholder
            .addWhen(() => !isBuyTab && !canViewBuyerBidButton, bwicActionsPlaceholder)
            .addWhen(() => canViewBuyerBidButton, buyerBidButton)
            .addWhen(() => isBdBuyTab, brokerDealerAction)
            .addWhen(() => canViewDealLookupLink, dealLookupLink)
            .add(externalLinks)
            .result()
            .map(c => new ColumnBuilder(c));

        return [[], middleColumns, rightColumns];
    }

    if (!bwics) return null;

    if (!bwics.length) return <EmptyPlaceholder />;

    const renderBwicPositionRows = (bwic: BwicSearchResult, columnType?: TableColumnStickType) => {
        const highlightTradedState =
            (!bwic.isMy && isSellerTrader && bwic.isClearingBankParticipant) ||
            (isBuyTab && isBrokerDealerTrader);

        const [left, middle, right] = getPositionListColumns();

        let columns: ColumnBuilder<BwicPositionSearchResult>[] = [];

        if (columnType === TableColumnStickType.Left) columns = left;
        if (columnType === TableColumnStickType.Middle) columns = middle;
        if (columnType === TableColumnStickType.Right) columns = right;

        return bwic.securities.map(s => {
            const { trade } = s as (BrokerDealerBwicPositionSearchResult | SellerBwicPositionSearchResult);
            return (
                <StickyTableRow
                    key={s.id}
                    rowKey={String(s.id)}
                    columns={columns}
                    dataItem={s}
                    context={{ bwic }}
                    className={cn({
                        highlighted: activePositionId && activePositionId === s.id,
                        tradedaway: highlightTradedState && (
                            (s as BrokerDealerBwicPositionSearchResult).isTradedAway ||
                            bwic.isColorDistribution ||
                            (bwic.status === BwicStatus.finished && !isActiveTrade(trade)) ||
                            bwic.status === BwicStatus.canceled ||
                            trade?.status === TradeStatus.rejected
                        ),
                        affirmed: highlightTradedState && trade?.status === TradeStatus.affirmed && !bwic.isColorDistribution,
                    })}
                />
            );
        });
    }

    const renderHeader = (stickType?: TableColumnStickType, headerContext?: object) => {
        const [bwic] = bwics;

        if (!bwic) return null;

        const [, middle, right] = getPositionListColumns();

        switch (stickType) {
            case TableColumnStickType.Middle:
                return middle.map(c => c.renderHeader(undefined, undefined, undefined, headerContext));
            case TableColumnStickType.Right:
                return right.map(c => c.renderHeader(undefined, undefined, undefined, headerContext));
            default:
                return null;
        }
    }

    const createBwicRowClassName = (bwic: BwicSearchResult) => {
        return cn({
            'is-active':
                activeBwic?.referenceName === bwic.referenceName &&
                !activePositionId
        });
    }

    return (
        <IntersectionObserverProvider disabled={totalRecordNumber <= 50}>
            <Table
                className={cn('bwic-monitor', { 'data-list-monitor-bdSeller': isSellTab && isBrokerDealerTrader })}
                collapsible={true}
                sticky={true}
                dataItems={bwics}
                defaultExpandAll={true}
                createSecurityCustomClassName={createBwicRowClassName}
                columns={getBwicColumns().map(c => new ColumnBuilder(c))}
                renderCollapsibleItem={renderBwicPositionRows}
                infiniteScrollEnabled={bwics.length < totalRecordNumber}
                onNextPageRequest={() => dispatch(allBWICSActions.loadNextPage(activeTab))}
                renderHeaderRow={renderHeader}
            />
            <AllBwicsControlPanel />
            {companyDetailsPanelVisible && <CompanyDetailsPanel />}
        </IntersectionObserverProvider>
    );
}
